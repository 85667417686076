import { Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { breakpoints } from 'swp-components/lib/assets/config/style'
import { Container } from 'swp-components/lib/components/bootstrap'
import { Button, DataLayer } from 'swp-components/lib/components/common'
import DataLayerScope from 'swp-components/lib/components/common/data-layer-scope'
import TOLNews from 'swp-components/lib/components/news'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import SectionHeading from '@/components/section-heading'
import Slider from '@/components/slider'
import useNewsTipsDataLayer from '@/hooks/data-layers/news-tips/use-news-tips-page'
import useColumns from '@/hooks/use-columns'
import generateSliderOptions from '@/util/generate-slider-options'

import style from './style'

const TipsDetail = ({ color, article, news, locale = 'th', ...props }) => {
  let { columns } = useColumns([
    { value: breakpoints.sm, columns: 0 },
    { value: breakpoints.md, columns: 0 },
    { value: breakpoints.lg, columns: 1 },
  ])
  let sliderOptions = generateSliderOptions(240)
  let { handleMessage, createScopeProps } = useNewsTipsDataLayer()
  return (
    <DataLayerProvider handler={handleMessage}>
      <Container className="mb-5">
        <Row>
          <Col md={12} lg={8}>
            <div className="py-3" css={style.article}>
              <SectionHeading
                tag="h1"
                variant={columns === 1 ? 'underline' : ''}
                alignment="left"
                className="py-3"
              >
                {article.title}
              </SectionHeading>
              <DataLayerScope
                {...createScopeProps('QuickTipsDetailArticle', {
                  article,
                  header: article.title,
                })}
              >
                <TOLNews.Article article={article} />
              </DataLayerScope>
            </div>
          </Col>

          {news.length > 0 && (
            <>
              <Col
                md={0}
                lg={4}
                className="d-none d-sm-none d-md-none d-lg-block"
              >
                <div className="py-3">
                  <SectionHeading
                    tag="h2"
                    className="py-1"
                    variant={'line-through'}
                  >
                    <span css={style.latestNewsHeader}>ข่าวสารล่าสุด</span>
                  </SectionHeading>
                  {news.map(articles => (
                    <DataLayer
                      selector={`QuickTipsDetail.Card#${articles.id}`}
                      triggers={['click']}
                      context={articles}
                      key={articles.id}
                      render={ref => (
                        <div
                          className="py-1"
                          ref={ref}
                          css={style.box}
                          style={{ width: `${100 / columns}%` }}
                        >
                          <TOLNews.Card
                            css={style.card}
                            color={color}
                            article={articles}
                          />
                        </div>
                      )}
                    />
                  ))}
                  <div className="d-flex justify-content-center my-3">
                    <DataLayer
                      selector={`QuickTipsDetail.ViewAllButton`}
                      triggers={['click']}
                      render={ref => (
                        <Button
                          color="orange"
                          variant="outlined"
                          ref={ref}
                          link={{
                            url: `${
                              locale !== 'th' ? '/en' : ''
                            }/trueonline/tips`,
                            newTab: true,
                          }}
                        >
                          <FormattedMessage
                            id="viewAll"
                            defaultMessage="View All"
                          />
                        </Button>
                      )}
                    />
                  </div>
                </div>
              </Col>
              <Col
                md={0}
                lg={4}
                className="d-block d-sm-block d-md-block d-lg-none"
              >
                <div className="py-3">
                  <SectionHeading
                    tag="h2"
                    className="py-3"
                    variant={'line-through'}
                  >
                    <span css={style.latestNewsHeader}>ข่าวสารล่าสุด</span>
                  </SectionHeading>
                  <Slider options={sliderOptions}>
                    {news.map(articles => (
                      <DataLayer
                        selector={`QuickTipsDetail.Card#${articles.id}`}
                        triggers={['click']}
                        context={articles}
                        key={articles.id}
                        render={ref => (
                          <div
                            className="py-1"
                            ref={ref}
                            css={style.box}
                            style={{ width: `${100 / columns}%` }}
                          >
                            <TOLNews.Card
                              css={style.card}
                              color={color}
                              article={articles}
                            />
                          </div>
                        )}
                      />
                    ))}
                  </Slider>
                  <div className="d-flex justify-content-center my-3">
                    <DataLayer
                      selector={`QuickTipsDetail.ViewAllButton`}
                      triggers={['click']}
                      render={ref => (
                        <Button
                          color="orange"
                          variant="outlined"
                          ref={ref}
                          link={{
                            url: `${
                              locale !== 'th' ? '/en' : ''
                            }/trueonline/tips`,
                            newTab: true,
                          }}
                        >
                          <FormattedMessage
                            id="viewAll"
                            defaultMessage="View All"
                          />
                        </Button>
                      )}
                    />
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </DataLayerProvider>
  )
}

export default TipsDetail
