import React, { useEffect, useState } from 'react'
import Footer from 'swp-components/lib/components/footer'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import Header from '@/components/header'
import Newrelic from '@/components/newrelic-agent'
import Seo from '@/components/seo'
import TipsDetail from '@/components/tips-detail'
import useHomePageDataLayer from '@/hooks/data-layers/use-home-page'
import NotFoundPage from '@/pages/404'
import strapi from '@/strapi'

const TOLTipsDetail = ({ serverData, location }) => {
  let [isWindowDefined, setIsWindowDefined] = useState(false)
  let [
    seo,
    headerScript,
    brandHeaders,
    productServices,
    footerHeaders,
    chatShop,
    threeLatestNews,
    newsDetail,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)
  let { handleMessage, createScopeProps } = useHomePageDataLayer()

  useEffect(() => {
    setIsWindowDefined(true)
  }, [isWindowDefined])

  if (newsDetail == null || newsDetail.length <= 0) {
    return <NotFoundPage />
  }
  newsDetail.category = []
  newsDetail.tags.map(a => {
    newsDetail.category.push({
      categoryName: a.name,
      categoryLink: `${
        serverData.locale !== 'th' ? '/en' : ''
      }/trueonline/tips/tag/${a.slug}`,
      link: {
        url: `${serverData.locale !== 'th' ? '/en' : ''}/trueonline/tips/tag/${
          a.slug
        }`,
        newTab: true,
      },
    })
  })
  newsDetail.link = {
    url: `${serverData.locale !== 'th' ? '/en' : ''}/trueonline/tips/${
      newsDetail.slug
    }`,
    newTab: true,
  }
  newsDetail.button = {
    buttonName: 'กลับ',
    link: {
      url: `${serverData.locale !== 'th' ? '/en' : ''}/trueonline/tips`,
      newTab: false,
    },
  }
  newsDetail.startDate = newsDetail.updatedAt
  newsDetail.description = newsDetail.content
  newsDetail.currentURL = location.href

  threeLatestNews.map(a => {
    a.link = {
      url: `${serverData.locale !== 'th' ? '/en' : ''}/trueonline/tips/${
        a.slug
      }`,
      newTab: true,
    }
    a.startDate = a.updatedAt
  })
  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo seo={newsDetail.seo} script={headerScript.script} />
        <Header items={brandHeaders} services={productServices} />

        {isWindowDefined && newsDetail && (
          <TipsDetail
            article={newsDetail}
            news={threeLatestNews}
            locale={serverData.locale}
            path={'trueonline_tips_detail'}
          />
        )}

        {footerHeaders && <Footer className="pb-4" items={footerHeaders} />}
        {chatShop && (
          <ChatShop
            content={chatShop}
            mariPlatform={ serverData.locale == 'th' ? "mari_tmh" : "truemoveh" }
            mariConfig={mariConfig}
          />
        )}
        <CookiesConsent />
      </DataLayerProvider>
    </>
  )
}

export default TOLTipsDetail

export const getServerData = async ({ params, pageContext }) => {
  let contents = await strapi.getContents(
    [
      'seo',
      'header-script',
      'brand-headers',
      'product-services',
      'footer-headers',
      'chat-and-shop',
      `tol-news-and-tips?_start=0&_limit=3&slug_ne=${params.slug}`,
      `tol-news-and-tips/${params.slug}`,
      'mari-chat-and-shop-config',
    ],
    pageContext.locale
  )

  let slug = params.slug
  return {
    props: { contents, slug: slug, locale: pageContext.locale },
  }
}
